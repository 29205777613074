<template>
  <div class="SignInComponent">
    <v-card max-width="500px" class="mx-auto">
      <v-card-title primary-title> パスワードリセット </v-card-title>
      <v-form ref="passwordResetForm">
        <v-card-text>
          <v-text-field
            name="userName"
            label="ユーザー名"
            id="userName"
            v-model="form.userName"
            :rules="[inputRules.required]"
            required
          ></v-text-field>
          <v-text-field
            name="verificationCode"
            label="認証コード"
            id="verificationCode"
            v-model="form.verificationCode"
            :rules="[inputRules.required]"
            required
          ></v-text-field>
          <v-text-field
            name="password"
            label="新パスワード"
            id="password"
            v-model="form.password"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            :rules="[
              inputRules.required,
              inputRules.minLength8,
              inputRules.letterType,
            ]"
            required
          ></v-text-field>
          <v-card-text class="pa-0 text-caption">
            パスワードに使える文字種は半角英数字と、以下に示す半角記号です<br />
            &nbsp;^$*.[]{}()?\"!@#%&amp;/\,&gt;&lt;':;|_~`=+-
          </v-card-text>
        </v-card-text>

        <v-card-actions>
          <v-btn
            @click="submit"
            :disabled="!isValid"
            class="mx-auto"
            color="info"
          >
            <span v-if="!isLoading">送信</span>
            <v-progress-circular v-else indeterminate size="32" />
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import cognito from "@/cognito";

export default {
  name: "PasswordResetComponent",
  data: () => ({
    form: {
      userName: "",
      verificationCode: "",
      password: "",
    },
    showPassword: false,
    inputRules: {
      required: (v) => !!v || "入力必須の項目です",
      minLength8: (v) => v.length >= 8 || "8文字以上で入力してください",
      letterType: (v) =>
        cognito.passwordReg.test(v) ||
        "パスワードに使えない文字が含まれています",
    },
    isValid: false,
    isLoading: false,
  }),
  watch: {
    form: {
      handler() {
        this.isValid = this.$refs.passwordResetForm.validate();
      },
      deep: true,
    },
  },
  methods: {
    async submit() {
      if (!this.$refs.passwordResetForm.validate()) {
        return;
      }

      this.isLoading = true;
      try {
        await cognito.passwordResetSubmit(
          this.form.userName,
          this.form.verificationCode,
          this.form.password
        );
        alert(
          "パスワードリセットが完了しました。\nサインイン画面に遷移します。"
        );
        this.$router.push("/SignIn");
      } catch (error) {
        console.error(error);
        const messages = ["パスワードリセットの実行に失敗しました。"];
        switch (error.name) {
          case "CodeMismatchException":
            messages.push("認証コードが間違っています。");
            break;
          case "ExpiredCodeException":
            messages.push(
              "認証コードの有効期限が切れています。",
              "再度パスワードリセット申請を行い、新しい認証コードを取得してください。"
            );
            break;
          case "InvalidPasswordException":
            messages.push("パスワードの要件を満たしていません。");
            break;
          default:
            messages.push(
              "時間を空けて再度実行していただくか、管理者までご連絡ください。",
              `エラーコード: ${error.name}`
            );
        }
        alert(messages.join("\n"));
      }
      this.isLoading = false;
    },
  },

  // ライフサイクルフック
  beforeMount() {
    document.title = "パスワードリセット | 安否確認システム";
  },
};
</script>
