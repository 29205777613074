import { Auth } from "aws-amplify";

export default {
  signIn: async (username, password) => {
    const result = await Auth.signIn(username, password);
    return result.challengeName;
  },
  signOut: async () => {
    await Auth.signOut();
  },
  isAuthenticated: async () => {
    try {
      await Auth.currentSession();
      return true;
    } catch (error) {
      return false;
    }
  },
  getJwtToken: async () => {
    try {
      const session = await Auth.currentSession();
      return session.getIdToken().getJwtToken();
    } catch (error) {
      console.log("error getting JWT token: ", error);
      return error;
    }
  },
  changePasswordBySelf: async (username, oldPassword, newPassword) => {
    const user = await Auth.signIn(username, oldPassword);
    await Auth.completeNewPassword(user, newPassword);
  },
  passwordResetRequest: async (username) => {
    await Auth.forgotPassword(username);
  },
  passwordResetSubmit: async (username, verificationCode, password) => {
    await Auth.forgotPasswordSubmit(username, verificationCode, password);
  },
  // NOTE: 半角英数字と以下の記号のみで文字列が構成されていることを確認する正規表現
  // ^$*.[]{}()?"!@#%&/\,><':;|_~`=+-
  passwordReg: /^[a-zA-Z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-]+$/,
};
